document.addEventListener('turbo:load', () => {
  $(document).on('click', '.redesign-wrapper .reset', (e) => {
    $(e.target).prop('disabled', true)
  })
})

document.addEventListener('turbo:load', () => {
  $(document).on('click',
    '.redesign-wrapper .package-type-filter .dropdown-menu .switch input', () => {
      if ($('.redesign-wrapper .reset').prop('disabled') == true) {
        $('.redesign-wrapper .reset').prop('disabled', false)
      }
    })
})

document.addEventListener('turbo:load', () => {
  let urlFilters = new URLSearchParams(window.location.search).get('package-group')
  !urlFilters && $('.redesign-wrapper .reset').prop('disabled', true)
})
